import { Component, OnInit } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  user:string;
  password:string;

  constructor( public navCtrl: NavController, public alertController:AlertController, private _api: ApiService ) { }

  ngOnInit() {

  }

  login(){
    if( !this.user || !this.password ){
      this.presentAlert( "El usuario y la contraseña son requeridos" );      
    }else if( this.user.length <= 3 ){
      this.presentAlert( "El usuario es muy pequeño" );     
    }else if( this.password.length <= 3 ){
      this.presentAlert( "La contraseña es muy pequeña" );     
    }else{
      
      let data_send = { user:this.user, pass: this.password, gettoken:true };
      this._api.login( data_send ).subscribe((data:any)=>{
        if( data.status == 1 ){
          this.user = "";
          this.password = "";
          localStorage.setItem("rol", data.rol);
          localStorage.setItem("user", data.user);
          localStorage.setItem("token", data.token);
          this.navCtrl.navigateForward("home/objetos");
        }else if( data.status == 2 ){        
          this.presentAlert( "Usuario inactivo" );
        }else{
          this.presentAlert( data.mensaje );
        }
        
      }); 
    }
  }

  recuperar(){
    this.navCtrl.navigateForward("recuperar");
  }

  async presentAlert( mensaje:string ) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Mensaje',
      // subHeader: 'Subtitle',
      message: mensaje,
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

}
